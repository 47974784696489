/**
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "../../../../components/MKBox";
import MKTypography from "../../../../components/MKTypography";
import Icon from "@mui/material/Icon";

function HorizontalTeamCard({ image, name, position, description, description1, span, icon, title }) {
  return (
    <Card sx={{ mt: 3 }}>
      <Grid container>
        <Grid sx={{ mt: -6, ml: 6 }}>
          
        </Grid>
        <Grid item xs={11} md={11} lg={11} sx={{ my: "auto" }}>
          <MKBox pt={{ xs: 1, lg: 2.5 }} pb={2.5} pr={4} pl={{ xs: 4, lg: 1 }} lineHeight={1}>
          
            <MKTypography variant="h4">
            
            {/* <MKTypography variant="h5" color="info" textGradient>
        <Icon>{icon}</Icon>{" "}
      </MKTypography> */}
            {name}
            <div class="ant-steps-item-icon" style={{marginLeft: '1vh'}}><span class="ant-steps-icon">{icon}</span></div></MKTypography>
            <MKTypography variant="h3" color={position.color} mb={1}>
              {position.label}
            </MKTypography>
            <MKTypography variant="h5" fontWeight='bold' color="text">
              {title}
            </MKTypography>           
            <MKTypography variant="body2" color="text">
              {description}
              <span style={{fontWeight: '600'}}>{span}</span>
              {description1}
            </MKTypography>
          </MKBox>
        </Grid>
      </Grid>
    </Card>
  );
}

// Typechecking props for the HorizontalTeamCard
HorizontalTeamCard.propTypes = {
  image: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  position: PropTypes.shape({
    color: PropTypes.oneOf([
      "primary",
      "secondary",
      "info",
      "success",
      "warning",
      "error",
      "dark",
      "light",
    ]),
    label: PropTypes.string,
  }).isRequired,
  description: PropTypes.string.isRequired,
};

export default HorizontalTeamCard;
