import React, { Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import { history } from "./store/index";
import { Triangle } from 'react-loader-spinner'

// About pages
// const AboutLayout = React.lazy(() => import('./components/layouts/AboutLayout/AboutLayout'));
// const AboutUsPage = React.lazy(() => import('./pages/about/AboutUs'));
// const ContactPage = React.lazy(() => import('./pages/about/Contact'));
// const LegalPage = React.lazy(() => import('./pages/about/Legal'));
// const TermsOfServicePage = React.lazy(() => import('./pages/about/TermsOfService'));
// const SecurityPracticesPage = React.lazy(() => import('./pages/about/SecurityPractices'));
// const FileRetentionPolicyPage = React.lazy(() => import('./pages/about/FileRetentionPolicy'));
// const AcceptableUsePolicyPage = React.lazy(() => import('./pages/about/AcceptableUsePolicy'));
// const SubprocessorsPage = React.lazy(() => import('./pages/about/Subprocessors'));
// const CopyrightNoticePage = React.lazy(() => import('./pages/about/CopyrightNotice'));
// const PaperlessPolicyPage = React.lazy(() => import('./pages/about/PaperlessPolicy'));
// const DMCAPolicyPage = React.lazy(() => import('./pages/about/DMCAPolicy'));
// const GDPRNoticePage = React.lazy(() => import('./pages/about/GDPRNotice'));
// const PrivacyPolicyPage = React.lazy(() => import('./pages/about/PrivacyPolicy'));
import AboutLayout from "./components/layouts/AboutLayout/AboutLayout";
import AboutUsPage from "./pages/about/AboutUs";
import ContactPage from "./pages/about/Contact";
import LegalPage from "./pages/about/Legal";
import TermsOfServicePage from "./pages/about/TermsOfService";
import SecurityPracticesPage from "./pages/about/SecurityPractices";
import FileRetentionPolicyPage from "./pages/about/FileRetentionPolicy";
import AcceptableUsePolicyPage from "./pages/about/AcceptableUsePolicy";
import SubprocessorsPage from "./pages/about/Subprocessors";
import CopyrightNoticePage from "./pages/about/CopyrightNotice";
import PaperlessPolicyPage from "./pages/about/PaperlessPolicy";
import DMCAPolicyPage from "./pages/about/DMCAPolicy";
import GDPRNoticePage from "./pages/about/GDPRNotice";
import PrivacyPolicyPage from "./pages/about/PrivacyPolicy";

// Product pages
const MainLayout = React.lazy(() => import('./components/layouts/main/MainLayout/MainLayout'));
const ProductsHomePage = React.lazy(() => import('./pages/products'));
const ProductPage = React.lazy(() => import('./components/layouts/HomeLayout/pages/LandingPages/Product'));
const ClassesPage = React.lazy(() => import('./pages/classes'));
const ClassPage = React.lazy(() => import('./pages/class'));
const GradingScalesPage = React.lazy(() => import('./pages/grading_scales'));
const AnswerSheetsPage = React.lazy(() => import('./pages/answer_sheets'));
const AssessmentsPage = React.lazy(() => import('./pages/assessments'));
const GradingPage = React.lazy(() => import('./pages/grading'));
const AddToGradingPage = React.lazy(() => import('./pages/add_to_grading'));

// Docs pages
import DocsLayout from "./components/layouts/DocsLayout/DocsLayout";
import DocsPage from "./pages/docs/Docs";
import Article1 from "./pages/docs/Docs/articles/article1";
import Article2 from "./pages/docs/Docs/articles/article2";


//import ProductPage from './components/layouts/HomeLayout/pages/LandingPages/Product';
//import MainLayout from "./components/layouts/main/MainLayout/MainLayout";
// import ClassesPage from "./pages/classes";
//import ClassPage from "./pages/class";
//import GradingScalesPage from "./pages/grading_scales";
// import AnswerSheetsPage from "./pages/answer_sheets";
// import AssessmentsPage from "./pages/assessments";
// import GradingPage from "./pages/grading";

// Home pages
const HomePage = React.lazy(() => import('./components/layouts/HomeLayout/pages/Home'));
//import HomePage from './components/layouts/HomeLayout/pages/Home';

// Other apps
const CreateAnswerSheetsApp = React.lazy(() => import('./pages/other_apps/create_answer_sheet'));
const ScanAnswerSheetsApp = React.lazy(() => import('./pages/other_apps/scan_answer_sheet'));
const GradeCalculatorApp = React.lazy(() => import('./pages/other_apps/grade_calculator'));
const CreateSurveyApp = React.lazy(() => import('./pages/other_apps/create_survey'));
const ScanSurveyApp = React.lazy(() => import('./pages/other_apps/scan_survey'));

// Error pages
const Error404Page = React.lazy(() => import('./pages/error/Error404Page'));

const href = window.location.href;
function Loading() {
    return <h2>🌀 Loading...</h2>;
}
export const AppRouter: React.FC = () => {
    if (href.includes("about.qa.omrquiz") || href.includes("about.omrquiz")) {
       // if (href.includes("/")) {
        return (
            <Routes >
                <Route path={"/"} element={<AboutLayout />} >
                    <Route path={"/"} element={<AboutUsPage />} />
                    <Route path={"/contact"} element={<ContactPage />} />
                    <Route path={"/legal"} element={<LegalPage />} />
                    <Route path={"/legal/tos"} element={<TermsOfServicePage />} />
                    <Route path={"/legal/security"} element={<SecurityPracticesPage />} />
                    <Route path={"/legal/file-retention-policy"} element={<FileRetentionPolicyPage />} />
                    <Route path={"/legal/acceptable-use"} element={<AcceptableUsePolicyPage />} />
                    <Route path={"/legal/subprocessors"} element={<SubprocessorsPage />} />
                    <Route path={"/legal/copyright-notice"} element={<CopyrightNoticePage />} />
                    <Route path={"/legal/paperless-policy"} element={<PaperlessPolicyPage />} />
                    <Route path={"/legal/dmca-policy"} element={<DMCAPolicyPage />} />
                    <Route path={"/legal/gdpr"} element={<GDPRNoticePage />} />
                    <Route path={"/legal/privacy-policy"} element={<PrivacyPolicyPage />} />
                </Route>
            </Routes>
        );
    }
    else if (href.includes("docs.qa.omrquiz") || href.includes("docs.omrquiz")) {
    //else if (href.includes("/")) {
        return (
            <Routes>
                <Route path={"/"} element={<DocsPage />} />
                <Route path={"/introduction"} element={<Article1 />} />
                <Route path={"/structure-of-form"} element={<Article2 />} />
                <Route path={"/:locale/"} element={<DocsPage />} />
                <Route path={"/:locale/introduction"} element={<Article1 />} />
                <Route path={"/:locale/structure-of-form"} element={<Article2 />} />
            </Routes>
        );
    }
    else if (href.includes("products.qa.omrquiz") || href.includes("products.omrquiz")) {
        //else if (href.includes("/")) {
        return (
            // <Suspense fallback={<Triangle />}>

            //<Routes location={history.location}>
                <Routes>
                <Route path={"/"}  >
                    <Route path={"/"} element={<ProductsHomePage />} />
                    <Route path={"/create-answer-sheet"} element={<CreateAnswerSheetsApp />} />
                    <Route path={"/read-answer-sheet"} element={<ScanAnswerSheetsApp />} />
                    <Route path={"/grade-calculator"} element={<GradeCalculatorApp />} />
                    <Route path={"/create-survey"} element={<CreateSurveyApp />} />
                    <Route path={"/read-survey"} element={<ScanSurveyApp />} />
                    <Route path={"/panel"} element={<MainLayout />}>
                        <Route path={"/panel/my-classes"} element={<ClassesPage />} />
                        <Route path={"/panel/class"} element={<ClassPage />} />
                        <Route path={"/panel/grading-scales"} element={<GradingScalesPage />} />
                        <Route path={"/panel/answer-sheets"} element={<AnswerSheetsPage />} />
                        <Route path={"/panel/assessments"} element={<AssessmentsPage />} />
                        <Route path={"/panel/grading"} element={<GradingPage />} />
                        <Route path={"/panel/add-to-grading"} element={<AddToGradingPage />} />
                    </Route>
                    <Route path="*" element={<Error404Page />} />
                    <Route path="*" element={<div>500</div>} />
                </Route>
                <Route path={"/:locale/"}  >
                    <Route path={"/:locale/"} element={<ProductsHomePage />} />
                    <Route path={"create-answer-sheet"} element={<CreateAnswerSheetsApp />} />
                    <Route path={"read-answer-sheet"} element={<ScanAnswerSheetsApp />} />
                    <Route path={"grade-calculator"} element={<GradeCalculatorApp />} />
                    <Route path={"create-survey"} element={<CreateSurveyApp />} />
                    <Route path={"read-survey"} element={<ScanSurveyApp />} />
                    <Route path={"panel"} element={<MainLayout />}>
                        <Route path={"/:locale/panel/my-classes"} element={<ClassesPage />} />
                        <Route path={"/:locale/panel/class"} element={<ClassPage />} />
                        <Route path={"/:locale/panel/grading-scales"} element={<GradingScalesPage />} />
                        <Route path={"/:locale/panel/answer-sheets"} element={<AnswerSheetsPage />} />
                        <Route path={"/:locale/panel/assessments"} element={<AssessmentsPage />} />
                        <Route path={"/:locale/panel/grading"} element={<GradingPage />} />
                        <Route path={"/:locale/panel/add-to-grading"} element={<AddToGradingPage />} />
                    </Route>
                    <Route path="*" element={<Error404Page />} />
                    <Route path="*" element={<div>500</div>} />
                </Route>
            </Routes>
            // </Suspense>
        );
    }
    else {
        return (
            <Routes>
                <Route path={"/"} element={<HomePage />} />
                <Route path={"/:locale/"} element={<HomePage />} />
            </Routes>
        );
    }
};