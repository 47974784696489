import common from "./common.json";
import popconfirm from "./popconfirm.json";
import home from "./home.json";
import head from "./head.json";
import header from "./header.json";
import team from "./team.json";
import featuring from "./featuring.json";
import newsletter from "./newsletter.json";
import footer from "./footer.json";
import createanswersheet from "./createanswersheet.json";
import scananswersheet from "./scananswersheet.json";
import gradecalculator from "./gradecalculator.json";
import productshome from "./productshome.json";
import docshome from "./docs.json";
import article1 from "./article1.json";
import article2 from "./article2.json";

export default {
    common,
    popconfirm,
    home,
    header,
    team,
    featuring,
    newsletter,
    footer,
    head,
    createanswersheet,
    scananswersheet,
    gradecalculator,
    productshome,
    docshome,
    article1,
    article2
}