/**
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/** 
  All of the routes for the Material Kit 2 React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Navbar.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `name` key is used for the name of the route on the Navbar.
  2. The `icon` key is used for the icon of the route on the Navbar.
  3. The `collapse` key is used for making a collapsible item on the Navbar that contains other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  4. The `route` key is used to store the route location which is used for the react router.
  5. The `href` key is used to store the external links location.
  6. The `component` key is used to store the component of its route.
  7. The `dropdown` key is used to define that the item should open a dropdown for its collapse items .
  8. The `description` key is used to define the description of
          a route under its name.
  9. The `columns` key is used to define that how the content should look inside the dropdown menu as columns,
          you can set the columns amount based on this key.
  10. The `rowsPerColumn` key is used to define that how many rows should be in a column.
*/

import i18n from '../../../locale/i18n';

// @mui material components
import Icon from "@mui/material/Icon";

// @mui icons
import HomeIcon from '@mui/icons-material/Home';
import DescriptionIcon from '@mui/icons-material/Description';
import AppsIcon from '@mui/icons-material/Apps';
import InfoIcon from '@mui/icons-material/Info';
import WebAssetIcon from '@mui/icons-material/WebAsset';

import ContactMailIcon from '@mui/icons-material/ContactMail';



// Pages
import AboutUs from "./layouts/pages/landing-pages/about-us";
import ContactUs from "./layouts/pages/landing-pages/contact-us";
import Author from "./layouts/pages/landing-pages/author";
//import SignIn from "layouts/pages/authentication/sign-in";

// Sections
import PageHeaders from "./layouts/sections/page-sections/page-headers";
import Features from "./layouts/sections/page-sections/featuers";
import Navbars from "./layouts/sections/navigation/navbars";
import NavTabs from "./layouts/sections/navigation/nav-tabs";
import Pagination from "./layouts/sections/navigation/pagination";
import Inputs from "./layouts/sections/input-areas/inputs";
import Forms from "./layouts/sections/input-areas/forms";
import Alerts from "./layouts/sections/attention-catchers/alerts";
import Modals from "./layouts/sections/attention-catchers/modals";
import TooltipsPopovers from "./layouts/sections/attention-catchers/tooltips-popovers";
import Avatars from "./layouts/sections/elements/avatars";
import Badges from "./layouts/sections/elements/badges";
import BreadcrumbsEl from "./layouts/sections/elements/breadcrumbs";
import Buttons from "./layouts/sections/elements/buttons";
import Dropdowns from "./layouts/sections/elements/dropdowns";
import ProgressBars from "./layouts/sections/elements/progress-bars";
import Toggles from "./layouts/sections/elements/toggles";
import Typography from "./layouts/sections/elements/typography";

const routes = [
  {
    name: i18n.t('header:home'),
    icon: <HomeIcon />,
    href: "https://www.omrquiz.com",
  },
  {
    name: i18n.t('header:documentation'),
    icon: <DescriptionIcon />,
    href: "https://docs.omrquiz.com"
  },
  {
    name: i18n.t('header:products'),
    icon: <AppsIcon />,
    href: "https://products.omrquiz.com",
    collapse: [
        {
          name: i18n.t('header:createAnswerSheet'),
          description: i18n.t('header:createAnswerSheetDescription'),
          href: "https://products.omrquiz.com/create-answer-sheet",
        },
        {
          name: i18n.t('header:scanAnswerSheet'),
          description: i18n.t('header:scanAnswerSheetDescription'),
          href: "https://products.omrquiz.com/read-answer-sheet",
        },
        {
          name: i18n.t('header:gradeCalculator'),
          description: i18n.t('header:gradeCalculatorDescription'),
          href: "https://products.omrquiz.com/grade-calculator",
        }
    ]
  },
  {
    name: i18n.t('header:about'),
    icon: <InfoIcon />,
    collapse: [
        {
          name: i18n.t('header:aboutUs'),
          description: i18n.t('header:aboutUsDescription'),
          href: "https://about.omrquiz.com",
        },
        {
          name: i18n.t('header:contact'),
          description: i18n.t('header:contactDescription'),
          href: "https://about.omrquiz.com/contact",
        },
        {
          name: i18n.t('header:legal'),
          description: i18n.t('header:legalDescription'),
          href: "https://about.omrquiz.com/legal",
        },
    ]
  },
  {
    name: i18n.t('header:webSites'),
    icon: <WebAssetIcon />,
    collapse: [
        {
          name: "eptimize",
          description: i18n.t('header:eptimize_description'),
          href: "https://www.eptimize.com/",
        },
        {
          name: "formize",
          description: i18n.t('header:formize_description'),
          href: "https://www.formize.com/",
        },
        {
          name: "codeporting",
          description: i18n.t('header:codeporting_com_description'),
          href: "https://www.codeporting.com/",
        },
        {
          name: "codeporting app",
          description: i18n.t('header:codeporting_app_description'),
          href: "https://www.codeporting.app/",
        },
        {
          name: "containerize",
          description: i18n.t('header:containerize_com_description'),
          href: "https://www.containerize.com/",
        },
        {
          name: "containerize",
          description: i18n.t('header:containerize_app_description'),
          href: "https://www.containerize.app/",
        },
        {
          name: "documentize",
          description: i18n.t('header:documentize_description'),
          href: "https://www.documentize.com/",
        },
        {
          name: "hostize",
          description: i18n.t('header:hostize_description'),
          href: "https://www.hostize.com/",
        },
        {
          name: "convertise",
          description: i18n.t('header:convertise_description'),
          href: "https://www.convertise.com/",
        }
    ]
  }
];

export default routes;
