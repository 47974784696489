import {
	ScanSurveyActionsTypes, IScanSurveyState, ScanSurveyActions,
} from './types';

const initialState : IScanSurveyState = {
	recognitionResult: '',
	recognitionResultId: -1,
    loading: false,
    error: '',
    success: false,
	create: {
        loading: false,
        error: '',
        success: false,
    },
};

const scanSurveyReducer = (state: IScanSurveyState = initialState, action: ScanSurveyActions) => {
	switch (action.type) {

		// ----------------------Scan Survey Actions-----------------------
		case ScanSurveyActionsTypes.POST_SCAN_SURVEY_APP: {
			return {
				...state,
				loading: true
			};
		}
		case ScanSurveyActionsTypes.POST_SCAN_SURVEY_APP_SUCCESS: {
			console.log("", action);
			return {
				...state,
				recognitionResult: action.data,
				create:{success:true, loading: false}
			};
		}	
		case ScanSurveyActionsTypes.POST_SCAN_SURVEY_APP_ERROR: {
			return {
				...state,
				create:{success:false, loading: false}
			};
		}				
		default:
			return state;
	}
};

export default scanSurveyReducer;
