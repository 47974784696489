import { takeLatest, put, call, select } from 'redux-saga/effects';
import { apiRequest } from '../../apiRequest';
import { URLs } from '../../urls';
import {
	PostLoginActionsTypes,
	ILoginResult,
	PostLoginRequestSucces,
	PostLoginRequestError,
	DefaultRegisterActionsTypes,
	IDefaultRegisterResult,
	DefaultRegisterRequestSucces,
	DefaultRegisterRequestError
} from './types';

function* sendPostLoginRequest(action: any) {
	try {
		// API call
		const response : ILoginResult = yield call(apiRequest, {
			endpoint: URLs.POST_LOGIN_ENDPOINT,
			method: 'POST',
			body: action.model
		});

		if (response.success == true) {
			let putData: PostLoginRequestSucces = {
				type: PostLoginActionsTypes.POST_LOGIN_SUCCESS,
				data: response,
				loading: false,
			};
			localStorage.accessToken = response.data.token;
      		localStorage.refreshToken = response.data.refreshToken;

			yield put<PostLoginRequestSucces>(putData);
		}

		if (response.success == false) {
			let putData: PostLoginRequestError = {
				type: PostLoginActionsTypes.POST_LOGIN_ERROR,
				error: response.error.toString(),
				loading: false
			};

			yield put<PostLoginRequestError>(putData);
		}

	} catch (e: any) {
		let putData: PostLoginRequestError = {
			type: PostLoginActionsTypes.POST_LOGIN_ERROR,
			error: e,
			loading: false
		};
		yield put<PostLoginRequestError>(putData);
	}
}

function* checkLoggedIn(action: any) {
	let userId = localStorage.getItem("UserId");
	console.log("/////////=====================", localStorage.getItem("UserId"));
	if (userId == 'null' || userId == null) {
		console.log("/////////=====================");
	try {
		// API call
		const response : IDefaultRegisterResult = yield call(apiRequest, {
			endpoint: URLs.GET_DEFAULT_REGISTER_ENDPOINT,
			method: 'GET',
		});
		console.log("////////////", response);
		if (response.success == true) {
			let putData: DefaultRegisterRequestSucces = {
				type: DefaultRegisterActionsTypes.DEFAULT_REGISTER_SUCCESS,
				data: response,
				loading: false,
			};
			localStorage.UserId = response.data;
			localStorage.setItem("UserId", response.data);
			console.log("/+-+--+-+-+-+-", localStorage.getItem("UserId"));
			yield put<DefaultRegisterRequestSucces>(putData);
		}

		if (response.success == false) {
			let putData: DefaultRegisterRequestError = {
				type: DefaultRegisterActionsTypes.DEFAULT_REGISTER_ERROR,
				error: response.error.toString(),
				loading: false
			};

			yield put<DefaultRegisterRequestError>(putData);
		}

	} catch (e: any) {
		let putData: DefaultRegisterRequestError = {
			type: DefaultRegisterActionsTypes.DEFAULT_REGISTER_ERROR,
			error: e,
			loading: false
		};
		yield put<DefaultRegisterRequestError>(putData);
	}
	}
  }


export function* handler() {
	yield takeLatest(PostLoginActionsTypes.POST_LOGIN, sendPostLoginRequest);
	yield takeLatest(DefaultRegisterActionsTypes.DEFAULT_REGISTER, checkLoggedIn);
}

