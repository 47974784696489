import { takeLatest, put, call, select } from 'redux-saga/effects';
import { apiRequest } from '../../apiRequest';
import { URLs } from '../../urls';

import { ScanAnswerSheetActionsTypes, IScanAnswerSheetResult, ScanAnswerSheetRequestSucces, ScanAnswerSheetRequestError
} from './types';

function* sendScanAnswerSheetRequest(action: any) {
	try {
		// API call
		const response : IScanAnswerSheetResult = yield call(apiRequest, {
			endpoint: URLs.SCAN_ANSWER_SHEET_APP_ENDPOINT,
			method: 'POST',
			body: action.model
		});

		if (response.success == true) {
			console.log("response.data/////////", response.data);
			let putData: ScanAnswerSheetRequestSucces = {
				type: ScanAnswerSheetActionsTypes.POST_SCAN_ANSWER_SHEET_APP_SUCCESS,
				data: response.data,
				loading: false,
				success: response.success
			};
			yield put<ScanAnswerSheetRequestSucces>(putData);
		}

		if (response.success == false) {
			let putData: ScanAnswerSheetRequestError = {
				type: ScanAnswerSheetActionsTypes.POST_SCAN_ANSWER_SHEET_APP_ERROR,
				error: response.error.toString(),
				loading: false
			};
			yield put<ScanAnswerSheetRequestError>(putData);
		}

	} catch (e: any) {
		let putData: ScanAnswerSheetRequestError = {
			type: ScanAnswerSheetActionsTypes.POST_SCAN_ANSWER_SHEET_APP_ERROR,
			error: e,
			loading: false
		};
		yield put<ScanAnswerSheetRequestError>(putData);
	}
}

export function* handler() {
	yield takeLatest(ScanAnswerSheetActionsTypes.POST_SCAN_ANSWER_SHEET_APP, sendScanAnswerSheetRequest);
}

