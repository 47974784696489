import {
	ScanAnswerSheetActionsTypes, IScanAnswerSheetState, ScanAnswerSheetActions,
} from './types';

const initialState : IScanAnswerSheetState = {
	recognitionResult: '',
	recognitionResultId: -1,
    loading: false,
    error: '',
    success: false,
	create: {
        loading: false,
        error: '',
        success: false,
    },
};

const scanAswerSheetReducer = (state: IScanAnswerSheetState = initialState, action: ScanAnswerSheetActions) => {
	switch (action.type) {

		// ----------------------Scan Answer Sheet Actions-----------------------
		case ScanAnswerSheetActionsTypes.POST_SCAN_ANSWER_SHEET_APP: {
			return {
				...state,
				loading: true
			};
		}
		case ScanAnswerSheetActionsTypes.POST_SCAN_ANSWER_SHEET_APP_SUCCESS: {
			console.log("", action);
			return {
				...state,
				recognitionResult: action.data,
				create:{success:true, loading: false}
			};
		}	
		case ScanAnswerSheetActionsTypes.POST_SCAN_ANSWER_SHEET_APP_ERROR: {
			return {
				...state,
				create:{success:false, loading: false}
			};
		}				
		default:
			return state;
	}
};

export default scanAswerSheetReducer;
