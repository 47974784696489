import React from 'react';
import { Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import i18n from '../../../locale/i18n';

import { PageTitle } from '../../../components/common/PageTitle/PageTitle';
import { useResponsive } from '../../../hooks/useResponsive';
import * as S from '../Legal.styles';

const link = "/";

const AcceptableUsePolicyPage: React.FC = (props: any) => {
    const { isDesktop } = useResponsive();
    const { t } = useTranslation();
    
  
    const desktopLayout = (
      <>
      <S.H1 level={1}>Acceptable Use Policy</S.H1>
      <S.ContentRight>Last Updated: 15 June 2023</S.ContentRight>

      <S.H5 level={2}>Use of the OMR Quiz App is subject to this Acceptable Use Policy.</S.H5>
      <S.Paragraph>The customer agrees not to, and not to allow third parties to use the Services:</S.Paragraph>

      <S.Tab>• to violate, or encourage the violation of, the legal rights of others (for example, this may include allowing Customer End Users to infringe or misappropriate the intellectual property rights of others in violation of the Digital Millennium Copyright Act);</S.Tab>
      <S.Tab>• to permit any third party that is not an Authorized User to access or use a username or password for the Services;</S.Tab>
      <S.Tab>• to engage in, promote or encourage illegal activity;</S.Tab>
      <S.Tab>• to attempt to reverse engineer, decompile, hack, disable, interfere with, disassemble, modify, copy, translate, or disrupt the features, functionality, integrity, or performance of the Services (including any mechanism used to restrict or control the functionality of the Services);</S.Tab>
      <S.Tab>• to sublicense, resell, time share or similarly exploit the Services;</S.Tab>
      <S.Tab>• to access the Services in order to build a similar or competitive product or service or copy any ideas, features, functions, or graphics of the Services;</S.Tab>
      <S.Tab>• for any unlawful, invasive, infringing, defamatory or fraudulent purpose (for example, this may include phishing, creating a pyramid scheme or mirroring a website);</S.Tab>
      <S.Tab>• to intentionally distribute viruses, worms, Trojan horses, corrupted files, hoaxes, or other items of a destructive or deceptive nature;</S.Tab>
      <S.Tab>• to interfere with the use of the Services, or the equipment used to provide the Services, by customers, or other authorized users;</S.Tab>
      <S.Tab>• to disable, interfere with or circumvent any aspect of the Services; or</S.Tab>
      <S.Tab>• to generate, distribute, publish or facilitate unsolicited mass email, promotions, advertisements or other solicitations (“spam”).</S.Tab>
      </>
    );
  
    const mobileAndTabletLayout = (
      <Row gutter={[20, 24]}>
        <Col span={24}>
          
        </Col>
  
        <Col span={24}>
          
        </Col>
  
        <Col span={24}>
          
        </Col>
  
        <Col span={24}>
          
        </Col>
      </Row>
    );
  
    return (
      <>
        <PageTitle title={t('head:aboutUsTitle')} description={t('head:aboutUsDescription')} url='href' noIndex={true}></PageTitle>
        {isDesktop ? desktopLayout : mobileAndTabletLayout}
      </>
    );
  };

export default AcceptableUsePolicyPage